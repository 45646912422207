import React from 'react';
import { graphql, Link } from 'gatsby';
import _ from 'lodash';
import urljoin from 'url-join';
import Reactmarkdown from 'react-markdown';
import { Disqus } from 'gatsby-plugin-disqus';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PostCard from '../components/post-card/post-card';
import PostDetails from '../components/post-details/post-details';
import {
  FacebookShareButton,
  TwitterShareButton,
  PinterestShareButton,
} from 'react-share';
import {
  IoLogoFacebook,
  IoLogoTwitter,
  IoLogoPinterest,
} from 'react-icons/io';
import {
  BlogPostDetailsWrapper,
  RelatedPostWrapper,
  RelatedPostItems,
  RelatedPostTitle,
  RelatedPostItem,
  BlogPostFooter,
  PostShare,
  PostTags,
  BlogPostComment,
} from './templates.style';

const BlogPostTemplate = (props: any) => {
  const { data: { allStrapiArticle: {edges}, strapiArticle, site } } = props;
  const post = strapiArticle;
  const title = post.title;
  const slug = post.slug;
  const siteUrl = site.siteMetadata.siteUrl;
  const shareUrl = urljoin(siteUrl, slug);
  const ReactMarkdownCustomImageComponent: React.FC<{props: any}> = (props) => (
     <img {...props} style={{maxWidth: '100%'}} />
  );
  
  console.log('siteUrl+slug', siteUrl+slug);
  return (
    <Layout>
      <SEO
        title={title}
        description={post.description}
      />
      <BlogPostDetailsWrapper>
        <PostDetails
          title={title}
          date={post.published_date}
          preview={
            post.coverImage == null
              ? null
              : post.coverImage.childImageSharp.fluid
          }
          description={
          <Reactmarkdown 
            renderers={{image: ReactMarkdownCustomImageComponent}}
            >
              {post.content}
          </Reactmarkdown>
          }
          imagePosition="left"
        />

        <BlogPostFooter
          className={post.coverImage === null ? 'center' : ''}
        >
          {post.article_tags === null ? null : (
            <PostTags className="post_tags">
              {post.article_tags.map((tag: {tag_name: string}, index: number) => (
                <Link key={index} to={`/tags/${_.kebabCase(tag.tag_name)}/`}>
                  {`#${tag.tag_name}`}
                </Link>
              ))}
            </PostTags>
          )}
          <PostShare>
            <span>Share This:</span>
            <FacebookShareButton url={shareUrl} quote={post.description}>
              <IoLogoFacebook />
            </FacebookShareButton>
            <TwitterShareButton url={shareUrl} title={title}>
              <IoLogoTwitter />
            </TwitterShareButton>
            <PinterestShareButton
              url={shareUrl}
              media={(post.coverImage) ? urljoin(siteUrl, post.coverImage.childImageSharp.fluid.src) : null}
            >
              <IoLogoPinterest />
            </PinterestShareButton>
          </PostShare>
        </BlogPostFooter>
        <BlogPostComment
          className={post.coverImage === null ? 'center' : ''}
        >
          <Disqus
            config={{
              /* Replace PAGE_URL with your post's canonical URL variable */
              url: `${siteUrl + '/' + slug}`,
              /* Replace PAGE_IDENTIFIER with your page's unique identifier variable */
              identifier: post.id,
              /* Replace PAGE_TITLE with the title of the page */
              title,
          }}
          />
        </BlogPostComment>
      </BlogPostDetailsWrapper>

      {edges.length !== 0 && (
        <RelatedPostWrapper>
          <RelatedPostTitle>Related Posts</RelatedPostTitle>
          <RelatedPostItems>
            {edges.map(({ node }: any) =>  {
              return (node.id !== post.id) ? (
              <RelatedPostItem key={node.id}>
                <PostCard
                  title={node.title || node.id}
                  url={`/${node.slug}`}
                  image={
                    node.coverImage == null
                      ? null
                      : node.coverImage.childImageSharp.fluid
                  }
                  tags={node.article_tags.map((article_tag:{tag_name: string}) => article_tag.tag_name )}
                />
              </RelatedPostItem>
            ) : null;
           })}
          </RelatedPostItems>
        </RelatedPostWrapper>
      )}
    </Layout>
  );
};

export default BlogPostTemplate;

export const pageQuery = graphql`
  query ArticleTemplate($id: String! $tagRelated: StrapiArticleArticle_tagsFilterInput) {
    site {
      siteMetadata {
        siteUrl
       }
     }
     allStrapiArticle(
       limit: 4
       sort: { fields: [published_date], order: DESC }
       filter: {article_tags: {elemMatch: $tagRelated}}
       ) {
      edges {
        node {
          id
          slug
          title
          published_date
          coverImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          article_tags {
            tag_name
          }
        }
      }
    }
    strapiArticle(id: {eq: $id}) {
      id
      title
      slug
      description
      content
      coverImage {
        childImageSharp {
          fluid(maxWidth: 480, maxHeight: 285, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
      article_tags {
        tag_name
      }
     } 
    }
`;



